import { isNil } from 'lodash';
import { serviceUrl } from '../config/constants';
import {
  BaseResponse, fetchGetMethod, fetchPostMethod, fetchPostWithFormDataMethod, getResponseObject,
  getResponseObjectWithoutBody
} from '../rpc/api';

const searchUsersErrorMessageKey = 'app.error.users_service.search_users';
const createSingleUserErrorMessageKey = 'app.error.users_service.create_user';
const getOrganizationUsersErrorMessageKey = 'app.error.users_service.create_user';

export enum UserType {
  unknown = 0,
  participant = 1,
  programAdmin = 2,
  institutional = 3,
  accountManager = 4,
  superAdmin = 5,
  externalPartner = 6,
}

export enum UserPermissions {
  canVisualizeProfileIndividualReport = 1,
  canVisualizeFullIndividualReport = 2,
  canVisualizeChatBot = 4,
  canTalkToChatBot = 8,
  canVisualizeVirtualAccount = 16,
  canVisualizeUser = 32,
  canAddUser = 64,
  canResetUserPassword = 128,
  canManageUser = 256,
  canVisualizeUserPrograms = 512,
  canVisualizeProgramExperience = 1024,
  canAddProgramExperience = 2048,
  canRenameProgram = 4096,
  canAddSubProgram = 8192,
  canEditSubProgram = 16384,
  canManageSubProgram = 32768,
  canSubProgramQrCodeInviteNewUsers = 65536,
  canVisualizeReport = 131072,
  canAddReport = 262144,
}

export enum Languages {
  all = 0,
  english = 1,
  japanese = 2,
  chinese = 3,
  spanish = 4,
  catalan = 5,
  chinese_s = 6,
}

export enum SaveUserStatus {
  All = 0,
  Success = 1,
  AlreadyExists = 2,
  InvalidLine = 3,
  WrongEmail = 4,
  MissingName = 5,
}

export type UserProgramDetails = {
  userId: number;
  formId: number;
  programName: string;
  groupName: string;
  status: number;
  completedOn?: string;
  validationFormStatus?: number;
}

export type UserPrograms = {
  userId: number;
  userName: string;
  details: UserProgramDetails[];
}

export class UserProgramDetailResponse extends BaseResponse<UserProgramDetailBody> { }
export type UserProgramDetailBody = UserPrograms;

export function loadUserProgramDetails(token: string, userId: number): Promise<UserProgramDetailResponse> {
  const body = {
    UserId: userId
  };
  const response = fetchPostMethod(`${serviceUrl}/api/user/GetUserPrograms`, token, body);
  return getResponseObject(response, createSingleUserErrorMessageKey);
}

export type ResetUsersPasswordBody = {}
export class ResetUsersPasswordResponse extends BaseResponse<ResetUsersPasswordBody> { }

export type ResetUserPasswordType = {
  UserId: number;
  NewPassword: string;
}

export function resetUsersPassword(
  token: string, passwords: ResetUserPasswordType[]
): Promise<ResetUsersPasswordResponse> {
  const payload = {
    Passwords: passwords
  }
  const response = fetchPostMethod(
    `${serviceUrl}/api/user/ChangePasswordBatch`, token, payload);
  return getResponseObjectWithoutBody(response, searchUsersErrorMessageKey);
}

export function remove2FA(
  token: string, id: number
): Promise<ResetUsersPasswordResponse> {
  const payload = {
    id
  }
  const response = fetchPostMethod(
    `${serviceUrl}/api/user/RemoveTwoFactorSecret`, token, payload);
  return getResponseObjectWithoutBody(response, searchUsersErrorMessageKey);
}

export type CreateNewUserBody = SaveUserStatus;
export class CreateNewUserResponse extends BaseResponse<CreateNewUserBody> { }

export class SearchUsersResponse extends BaseResponse<SearchUsersBody> { }

export type SearchUsersBody = SearchUsersDetails[];
export type SearchUsersDetails = {
  id: number;
  name: string;
  login: string;
  isActive: boolean;
  userType: UserType;
  hasTwoFactorSecret: boolean;
}

export type SearchUsersPermissionDetails = {
  userId: number;
  userName: string;
  userEmail: string;
  hasPermission: boolean;
  capabilities: number[];
}

export type CreateNewUserPayloadRequest = {
  id?: number;
  organizationIds?: number[];
  programIds?: number[];
  name?: string;
  login?: string;
  language?: number;
  userType?: number;
  isAnonymous?: number;
  sendEmail?: boolean;
};

export type CreateNewUserBatchPayloadRequest = {
  file: File,
  organizationId: number,
  sendEmail: boolean
};

export type AddUserPermissionExcelFilePayloadRequest = {
  file: File,
  permissions: number[]
};

export function searchUsers(
  token: string, organizationId: number, userNameQuery: string, userEmailQuery: string, userType?: number
): Promise<SearchUsersResponse> {
  const organizationIdQuery = (isNil(organizationId) || isNaN(organizationId)) ? 0 : organizationId;
  const response = fetchGetMethod(
    `${serviceUrl}/api/organization/getusers?organizationId=${organizationIdQuery}&userName=${userNameQuery}&userEmail=${userEmailQuery}&userType=${userType ?? 0}`, token);
  return getResponseObject<SearchUsersBody>(response, searchUsersErrorMessageKey);
}

export function saveUser(token: string, user: CreateNewUserPayloadRequest): Promise<CreateNewUserResponse> {
  const response = fetchPostMethod(`${serviceUrl}/api/user/SaveUser`, token, user);
  return getResponseObject(response, createSingleUserErrorMessageKey);
}

export type UserErrorResponse = {
  userError: SaveUserStatus;
  email: string;
  lineNumber: number;
}

export type CreateNewUserBatchBody = UserErrorResponse[];
export class CreateNewUserBatchResponse extends BaseResponse<CreateNewUserBatchBody> { }

export function createNewUserBatch(token: string, file: File, organizationId: number, sendEmail: boolean): Promise<CreateNewUserBatchResponse> {
  const formData = new FormData();
  formData.append('organizationId', organizationId.toString());
  formData.append('sendEmail', sendEmail.toString());
  formData.append('file', file, 'users.xlsx');

  const response = fetchPostWithFormDataMethod(`${serviceUrl}/api/user/AddUserBatch`, token, formData);
  return getResponseObject(response, createSingleUserErrorMessageKey);
}

export type OrganizationUser = {
  id: number;
  login: string; // email
  name: string;
  isActive: boolean;
};

export type OrganizationsUsersBody = {
  users: OrganizationUser[];
}
export class OrganizationsUsersResponse extends BaseResponse<OrganizationsUsersBody> { }

export function getOrganizationUsers(token: string, organizationId: number): Promise<OrganizationsUsersResponse> {

  const response = fetchGetMethod(
    `${serviceUrl}/api/organization/GetUsers?OrganizationId=${organizationId}`, token);
  return getResponseObject<OrganizationsUsersBody>(response, getOrganizationUsersErrorMessageKey);

}

export function getOrganizationUsersOnlyParticipants(token: string, organizationId: number): Promise<OrganizationsUsersResponse> {

  const response = fetchGetMethod(
    `${serviceUrl}/api/organization/GetUsers?OrganizationId=${organizationId}&UserType=1`, token);
  return getResponseObject<OrganizationsUsersBody>(response, getOrganizationUsersErrorMessageKey);

}

export type SearchUsersPermissionBody = {}
export class SearchUsersPermissionResponse extends BaseResponse<SearchUsersPermissionBody> { }

export function searchUsersPermission(
  token: string, organizationId: number, programId?: number
): Promise<SearchUsersPermissionBody> {
  const payload = {
    organizationId,
    programId
  }
  const response = fetchPostMethod(
    `${serviceUrl}/api/user/GetUserPermissionProfile`, token, payload);
  return getResponseObject<SearchUsersPermissionBody>(response, searchUsersErrorMessageKey);
}

export type UserPermissionModel = {
  userId: number;
  permissions: number[];
}

export type UpdateUsersPermissionBody = {}
export class UpdateUsersPermissionResponse extends BaseResponse<UpdateUsersPermissionBody> { }

export function updateUsersPermission(
  token: string, payload: UserPermissionModel[]
): Promise<UpdateUsersPermissionResponse> {
  const response = fetchPostMethod(
    `${serviceUrl}/api/user/SetUserPermission`, token, payload);
  return getResponseObjectWithoutBody(response, searchUsersErrorMessageKey);
}

export type UserOrganization = {
  organizationId: number;
  organizationName: string;
};

export type UserProgram = {
  programId: number;
  programName: string;
};

export type GetUserBody = {
  login?: string;
  organizations?: UserOrganization[];
  programs?: UserProgram[];
  name?: string;
  userType?: number;
  language?: number;
  permissions?: number[];
}
export class GetUserResponse extends BaseResponse<GetUserBody> { }

export function getUser(token: string, userId: number): Promise<GetUserResponse> {
  const response = fetchGetMethod(`${serviceUrl}/api/user/GetUser?userId=${userId}`, token);
  return getResponseObject(response, searchUsersErrorMessageKey);
}

export type UserAddPermissionErrorResponse = {
  userError: SaveUserStatus;
  email: string;
}

export type AddUserPermissionExcelBody = UserAddPermissionErrorResponse[];
export class AddUserPermissionExcelResponse extends BaseResponse<AddUserPermissionExcelBody> { }

export function addUserPermissionExcelFile(token: string, permissions: number[], file: File): Promise<AddUserPermissionExcelResponse> {
  const formData = new FormData();
  formData.append('permissions', permissions.toString());
  formData.append('file', file, 'users.xlsx');

  const response = fetchPostWithFormDataMethod(`${serviceUrl}/api/user/AddUserPermissionsExcelFile`, token, formData);
  return getResponseObject(response, createSingleUserErrorMessageKey);
}